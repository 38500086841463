import React from 'react'
import AliceCarousel from 'react-alice-carousel'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

import Showcases1 from '../images/showcases/1.jpg'
import Showcases2 from '../images/showcases/2.jpg'
import Showcases3 from '../images/showcases/3.jpg'
import Showcases4 from '../images/showcases/4.jpg'

const handleOnDragStart = e => e.preventDefault()

const items = [
  <img
    src={Showcases1}
    onDragStart={handleOnDragStart}
    className="img-feature mx-auto d-block"
    alt="sallon showcase"
  />,
  <img
    src={Showcases2}
    onDragStart={handleOnDragStart}
    className="img-feature mx-auto d-block"
    alt="sallon showcase"
  />,
  <img
    src={Showcases3}
    onDragStart={handleOnDragStart}
    className="img-feature mx-auto d-block"
    alt="sallon showcase"
  />,
  <img
    src={Showcases4}
    onDragStart={handleOnDragStart}
    className="img-feature mx-auto d-block"
    alt="sallon showcase"
  />
]

const ShowcasesPage = () => {
  return (
    <Layout>
      <SEO
        title="Showcases"
        description="The showcases of Nail salons are using our pedicure spa chairs to protect their beloved customers. Cleaner Safer and Faster sanitation."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container">
        <div className="row responsive-fh">
          <div className="col-md-4 animated fadeInLeft d-flex flex-column justify-content-center py-4 text-center">
            <h1>CONTÉGO SPA CHAIRS IN ACTION</h1>
            <p>
              Take a look at beautiful salons which are using our chairs to provide the safer,
              faster and cleaner services to customers.
            </p>
          </div>
          <div className="col-md-1" />
          <div className="col-md-7 d-flex justify-content-center animated fadeInRight py-4 d-flex align-items-center">
            <AliceCarousel
              autoPlay
              autoPlayInterval={4000}
              mouseDragEnabled
              items={items}
              fadeOutAnimation
              buttonsDisabled
              stopAutoPlayOnHover
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ShowcasesPage
